"use strict";
// Function to read a cookie
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var _i = 0, ca_1 = ca; _i < ca_1.length; _i++) {
        var c = ca_1[_i];
        c = c.trim();
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length);
    }
    return null;
}
function detectUserLocation() {
    var cookieRegion = readCookie('AGRIWEBB_REGION');
    // If the cookie is already set, no need to detect location via IP
    if (cookieRegion) {
        // change urls href by data-url
        var urls = document.getElementsByTagName('a');
        for (var i = 0; i < urls.length; i++) {
            var data_url = urls[i].getAttribute('data-url-' + cookieRegion.toLowerCase());
            if (data_url) {
                urls[i].setAttribute('href', data_url);
            }
        }
        return; // Skip IP detection if the cookie is defined
    }
}
document.addEventListener('DOMContentLoaded', function () {
    detectUserLocation();
});
