import ComponentUtilsMixin from '@/helpers/mixins/component-utils-mixins';
import Vue from 'vue';
export var TooltipDialog = Vue.component('tooltip-dialog', {
    mixins: [ComponentUtilsMixin],
    template: "<div class=\"tooltip-container\" ref=\"tooltipContainer\" @mouseenter=\"showTooltip\" @mouseleave=\"hideTooltip\">\n    <slot></slot>\n    <div v-if=\"visible\" ref=\"tooltip\" :class=\"positionClass\" class=\"tooltip-dialog\">\n      {{ tooltipContent }}\n    </div>\n  </div>",
    props: {
        tooltipContent: {
            type: String,
            required: true,
        },
    },
    data: function () { return ({
        visible: false,
        positionClass: 'tooltip-dialog--right'
    }); },
    methods: {
        showTooltip: function () {
            var _this = this;
            this.visible = true;
            this.$nextTick(function () {
                // Ensure that the tooltip is fully rendered before setting position
                _this.setTooltipPosition();
            });
        },
        hideTooltip: function () {
            this.visible = false;
        },
        setTooltipPosition: function () {
            var tooltip = this.$refs.tooltip;
            ;
            var container = this.$refs.tooltipContainer;
            ;
            if (tooltip && container) {
                var rect = container.getBoundingClientRect();
                var screenWidth = window.innerWidth;
                var tooltipWidth = tooltip.offsetWidth;
                // If the tooltip would overflow the screen on the right, position it to the left
                if (rect.right + tooltipWidth > screenWidth) {
                    this.positionClass = 'tooltip-dialog--left'; // Position tooltip to the left of the container
                }
                else {
                    this.positionClass = 'tooltip-dialog--right'; // Position tooltip to the right
                }
            }
        },
    },
});
