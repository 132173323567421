import { defineComponent } from "vue";
import clientCookie from "@/helpers/client-cookie";
var COOKIE_NAME = 'AGRIWEBB_REGION';
var DEFAULT_REGION = 'AU';
var LanguageMixin = defineComponent({
    data: function () {
        return {
            regionUrls: {},
            userCountryCode: DEFAULT_REGION,
        };
    },
    computed: {
        $regionSwitchAlert: function () {
            return document.getElementById('region-switch-alert');
        },
        $regionSwitchAlertSelect: function () {
            return document.getElementsByClassName('region-switch-alert-select');
        },
        $regionSwitchAlertFooterSelect: function () {
            return document.getElementById('region-switch-alert-footer-select');
        },
        regionCookie: function () {
            return clientCookie.readCookie(COOKIE_NAME);
        },
        hasRegionCookie: function () {
            return Boolean(this.regionCookie);
        },
        showSwitchRegionBanner: function () {
            if (this.userCountryCode === DEFAULT_REGION) {
                return false;
            }
            if (!this.regionCookie && this.userCountryCode === DEFAULT_REGION) {
                return false;
            }
            if (!this.regionCookie) {
                return true;
            }
            var currentRegion = this.getCurrentRegionFromUrl();
            if (Object.keys(this.regionUrls).includes(currentRegion)) {
                return this.regionCookie !== currentRegion;
            }
            return false;
        },
    },
    watch: {
        showSwitchRegionBanner: {
            immediate: true,
            handler: function (newVal) {
                var _a, _b, _c, _d;
                if (newVal) {
                    (_a = this.getTemplateElement()) === null || _a === void 0 ? void 0 : _a.classList.add('top-0');
                    (_b = this.getTemplateElement()) === null || _b === void 0 ? void 0 : _b.classList.add('sm:!top-[72px]');
                }
                else {
                    (_c = this.getTemplateElement()) === null || _c === void 0 ? void 0 : _c.classList.remove('top-0');
                    (_d = this.getTemplateElement()) === null || _d === void 0 ? void 0 : _d.classList.remove('sm:!top-[72px]');
                }
            }
        }
    },
    methods: {
        getTemplateElement: function () {
            return document.querySelector('#app > header + main[id^="template-"]');
        },
        getCurrentRegionFromUrl: function () {
            var pathRegion = window.location.pathname.split('/')[1].toUpperCase();
            return pathRegion || 'AU'; // Default to AU if no region is present in the path
        },
        detectUserLocation: function () {
            var _this = this;
            if (this.regionCookie) {
                console.log("Cookie for region already set:", this.regionCookie);
                return;
            }
            // IP-based region detection
            fetch("https://ipapi.co/json/")
                .then(function (response) {
                if (!response.ok) {
                    throw new Error("API response not ok: ".concat(response.status));
                }
                return response.json();
            })
                .then(function (locationData) {
                var userCountryCode = (locationData.country_code || "").toUpperCase();
                _this.userCountryCode = userCountryCode;
                if (Object.keys(_this.regionUrls).includes(userCountryCode)) {
                    clientCookie.createCookie(COOKIE_NAME, userCountryCode, 30);
                    // set default value for select region footer
                    var footerSselect = _this.$regionSwitchAlertFooterSelect;
                    if (!footerSselect)
                        return false;
                    for (var i = 0; i < footerSselect.options.length; i++) {
                        var element = footerSselect.options[i];
                        var _a = element.value.split(';'), iso = _a[0], url = _a[1];
                        if (iso === userCountryCode) {
                            element.selected = true;
                        }
                    }
                }
            })
                .catch(function (error) {
                console.error("Error detecting IP location:", error);
            });
        },
        populateRegionUrls: function () {
            var regionUrlsString = this.$regionSwitchAlertSelect[0].getAttribute('data-region-urls');
            if (regionUrlsString) {
                var result = Object.fromEntries(regionUrlsString
                    .split(",") // Split by comma
                    .filter(Boolean) // Remove empty entries if any
                    .map(function (entry) { return entry.split(";"); }) // Split by semicolon into key-value pairs
                );
                this.regionUrls = result;
            }
        },
        handleRegionSwitch: function (selectId) {
            var select = document.getElementById(selectId);
            if (!select)
                return;
            var _a = select.value.split(';'), iso = _a[0], url = _a[1];
            if (iso && url) {
                clientCookie.createCookie(COOKIE_NAME, iso, 30);
                window.location.href = url;
            }
        }
    },
    mounted: function () {
        this.detectUserLocation();
        this.populateRegionUrls();
    }
});
export default LanguageMixin;
