// ==============================ATTENTION========================================
// TODO: refactor this later
// ==============================ATTENTION========================================
export function headerMenubarRight() {
    document.addEventListener('DOMContentLoaded', function () {
        var menuItemsWithChildren = document.querySelectorAll('.menu-item-has-children > a');
        // Add event listeners for each menu item
        menuItemsWithChildren.forEach(function (menuItem) {
            var submenu = menuItem.nextElementSibling;
            if (!submenu)
                return;
            var timeoutId;
            menuItem.addEventListener('mouseenter', function () {
                var _this = this;
                clearTimeout(timeoutId);
                if (submenu) {
                    submenu.classList.add('visible');
                    this.classList.add('visible');
                }
                menuItemsWithChildren.forEach(function (item) {
                    var otherSubmenu = item.nextElementSibling;
                    if (item !== _this && otherSubmenu) {
                        otherSubmenu.classList.remove('visible');
                        item.classList.remove('visible');
                    }
                });
            });
            submenu.addEventListener('mouseenter', function () {
                clearTimeout(timeoutId); // Keep submenu visible when entering it
            });
            // Delayed hide on mouseleave
            menuItem.addEventListener('mouseleave', function (event) {
                timeoutId = setTimeout(function () {
                    if (!submenu.contains(event.relatedTarget)) {
                        submenu.classList.remove('visible');
                        menuItem.classList.remove('visible');
                    }
                }, 200); // Delay to allow smooth transition
            });
            submenu.addEventListener('mouseleave', function () {
                submenu.classList.remove('visible');
                menuItem.classList.remove('visible');
            });
        });
        // Handle menu and close buttons with null checks
        var menuButton = document.getElementById('btn-menu');
        var closeButton = document.getElementById('btn-close');
        var popupNavigation = document.getElementById('popup-navigation-right-desktop');
        if (menuButton && closeButton && popupNavigation) {
            menuButton.addEventListener('click', function () {
                popupNavigation.classList.remove('hidden');
                menuButton.classList.add('hidden');
                closeButton.classList.remove('hidden');
            });
            closeButton.addEventListener('click', function () {
                popupNavigation.classList.add('hidden');
                closeButton.classList.add('hidden');
                menuButton.classList.remove('hidden');
            });
        }
    });
}
export function checkHeaderWordpress() {
    document.addEventListener('DOMContentLoaded', function () {
        var header = document.getElementById('custom-header');
        var wpAdminBar = document.getElementById('wpadminbar');
        var menuMobile = document.getElementById('mobile-menu-toggle');
        if (header && wpAdminBar) {
            var adminBarHeight = wpAdminBar.offsetHeight;
        }
        if (menuMobile) {
            menuMobile.addEventListener('click', function () {
                var menu = document.getElementById('mobile-menu');
                if (menu) {
                    menu.classList.toggle('hidden');
                }
            });
        }
    });
}
