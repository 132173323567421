import ComponentUtilsMixin from '@/helpers/mixins/component-utils-mixins';
import Vue from 'vue';
export var AccordionItem = Vue.component('accordion-item', {
    mixins: [ComponentUtilsMixin],
    template: "<div class=\"accordion-item\">\n    <slot></slot>\n    <div ref=\"accordionContent\" class=\"accordion-item-content max-h-0 overflow-hidden transition-all duration-500\" :class=\"{'max-h-[1000px]': isOpen}\">\n      <slot name=\"content\" :isOpen=\"isOpen\"></slot>\n    </div>\n  </div>",
    props: {
        initialOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: function () { return ({
        isOpen: false,
    }); },
    computed: {
        parent: function () {
            return this.getParentComponent('accordion');
        },
    },
    mounted: function () {
        var _this = this;
        window.addEventListener('resize', function () {
            _this.adjustContentHeight();
        });
        this.bindContentClass();
    },
    watch: {
        initialOpen: {
            immediate: true,
            handler: function (newVal) {
                this.isOpen = newVal;
            }
        },
        isOpen: {
            immediate: true,
            handler: function (newVal) {
                var _this = this;
                if (!this.parent)
                    return;
                this.$emit('toggle', newVal);
                this.$nextTick(function () {
                    _this.adjustContentHeight();
                });
            }
        }
    },
    methods: {
        bindContentClass: function () {
            if (!this.parent)
                return;
            var content = this.$refs.accordionContent;
            content.classList.add('overflow-hidden', 'transition-all', 'duration-500', 'max-h-0');
        },
        toggle: function () {
            this.isOpen = !this.isOpen;
        },
        adjustContentHeight: function () {
            var contentHeight = this.$refs.accordionContent.scrollHeight;
            if (this.isOpen) {
                this.$refs.accordionContent.style.maxHeight = "".concat(contentHeight, "px");
            }
            else {
                this.$refs.accordionContent.style.maxHeight = '0px';
            }
        }
    }
});
var createAccordionTemplate = function () {
    return "<div class=\"accordion\">\n    <slot></slot>\n  </div>";
};
export default Vue.component('accordion', {
    template: createAccordionTemplate(),
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
    },
});
