import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { calculateProgress } from '@/helpers/swiper';
// ================================ ATTENTION =================================
// TODO: This is a temporary solution to the problem.
// TODO: Refactor soon
// ================================ ATTENTION =================================
export var blockScreenshotCarousel = function () {
    var progressIndicator = document.querySelector('.progress-indicator-horizontal');
    new Swiper('.block-screenshot-carousel-slider', {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 3.7,
        slidesOffsetBefore: 70,
        spaceBetween: 30,
        loop: true,
        autoplay: false,
        mousewheel: true,
        // Disabled autoplay for now
        // autoplay: {
        //     delay: 3000, // 3 seconds delay between slides
        //     disableOnInteraction: false,
        //     pauseOnMouseEnter: true,
        // },
        // pagination: {
        //     el: ".swiper-pagination-screenshot",
        //     type: "progressbar",
        // },
        navigation: {
            nextEl: ".custom-button-next",
            prevEl: ".custom-button-prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 1.2,
                spaceBetween: 10,
                slidesOffsetBefore: 20,
            },
            640: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesOffsetBefore: 20,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 30,
                slidesOffsetBefore: 20,
            },
            1024: {
                slidesPerView: 3.6,
                spaceBetween: 30,
                slidesOffsetBefore: 70,
            },
        },
        on: {
            slideChange: function (swiperInstance) {
                if (swiperInstance.slides.length > 1 && progressIndicator) {
                    var progress = calculateProgress(swiperInstance);
                    progressIndicator.style.width = "".concat(progress, "%");
                }
            },
        }
    });
};
