import $globalElements from '@/helpers/global-elements';
import Vue, { defineComponent } from 'vue';
import { createTemplate } from '@/lib/vue';
var defaultProps = {
    attributes: {
        ':open': 'open',
        ':show-close-button': 'showCloseButton',
        ':delayed-close': 'delayedClose'
    },
};
export var createDrawerTemplate = function (props) {
    if (props === void 0) { props = null; }
    return createTemplate(function (_a) {
        var attributes = _a.attributes;
        return "\n    <portal selector=\"#portal-target\">\n      <div v-if=\"open\"\n        @click=\"backdropClick\"\n        :style=\"{ display: isOpen ? 'block' : 'none', marginTop: offsetTop + 'px' }\"\n        style=\"display: none;\"\n        class=\"drawer-backdrop fixed inset-0 z-[150] bg-gray-900/50 w-full h-full overflow-hidden\">\n        <div\n          :style=\"{ width: width }\"\n          :class=\"{'-right-[100%]': !isOpenDelayed, 'right-0': isOpenDelayed}\"\n          class=\"transition-all duration-800 lg:rounded-tl-[22px] lg:rounded-bl-[22px] max-w-full lg:max-w-[1233px] p-[24px] lg:p-[38px] absolute bg-white top-2 lgtop-3 bottom-3 overflow-y-auto hide-scrollbar\">\n          <slot ".concat(attributes, "></slot>\n          <button v-if=\"showCloseButton\" @click=\"delayedClose\">close</button>\n        </div>\n      </div>\n    </portal>\n  ");
    }, props, defaultProps);
};
export default Vue.component('drawer', defineComponent({
    template: createDrawerTemplate(),
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: "100%"
        }
    },
    data: function () {
        return {
            isOpen: false,
            isOpenDelayed: false,
        };
    },
    computed: {
        $wpAdminBar: function () {
            return $globalElements.$wpAdminBar();
        },
        offsetTop: function () {
            return this.$wpAdminBar ? this.$wpAdminBar.outerHeight() : 0;
        },
    },
    methods: {
        delayedOpen: function () {
            var self = this;
            self.isOpen = true;
            document.body.style.overflow = 'hidden';
            setTimeout(function () {
                self.isOpenDelayed = true;
            }, 100);
        },
        delayedClose: function () {
            var self = this;
            self.isOpenDelayed = false;
            setTimeout(function () {
                self.isOpen = false;
                document.body.style.overflow = 'auto';
                self.$emit('close');
            }, 100);
        },
        bindKeyListener: function (event) {
            if (event.key === 'Escape') {
                this.delayedClose();
            }
        },
        backdropClick: function (event) {
            var target = event.target;
            if (target.classList.contains('drawer-backdrop')) {
                this.delayedClose();
            }
        },
    },
    watch: {
        open: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.delayedOpen();
                }
                else {
                    this.delayedClose();
                }
            },
        },
    },
    created: function () {
        document.addEventListener('keydown', this.bindKeyListener);
    },
    beforeDestroy: function () {
        document.removeEventListener('keydown', this.bindKeyListener);
    },
}));
