import Vue, { defineComponent } from 'vue';
import { CalculatePricing, } from '@/helpers/pricing/calculate-pricing';
import $ from '@/lib/jquery';
import $globalElements from '@/helpers/global-elements';
import ComponentUtilsMixin from '@/helpers/mixins/component-utils-mixins';
var calculator = new CalculatePricing(globalData.pricing_data);
var YEARLY_PERIOD = 'Yearly';
var MONTHLY_PERIOD = 'Monthly';
Vue.component('pricing-plan', defineComponent({
    mixins: [ComponentUtilsMixin],
    props: {
        planName: String,
        countryCode: String,
        dseThreshold: Number,
        discountPercentage: Number,
    },
    data: function () {
        return {
            originalPrice: '0',
            totalPrice: '0',
            showModalBuyNow: false,
        };
    },
    computed: {
        $mainHeader: function () {
            return $globalElements.$mainHeader();
        },
        $wpAdminBar: function () {
            return $globalElements.$wpAdminBar();
        },
        $regionBanner: function () {
            return $('#region-switch-alert');
        },
        $planHeader: function () {
            return this.$refs.header ? $(this.$refs.header) : null;
        },
        $planContent: function () {
            return this.$refs.content ? $(this.$refs.content) : null;
        },
        parentData: function () {
            return this.getParentData('template-pricing') || {};
        },
        period: function () {
            return this.parentData.period === 'yearly'
                ? YEARLY_PERIOD
                : MONTHLY_PERIOD;
        },
        periodText: function () {
            return this.period === YEARLY_PERIOD ? '/ year' : '/ month';
        },
        yearlyPrice: function () {
            return this.priceByPeriod(YEARLY_PERIOD, true);
        },
        monthlyPrice: function () {
            return this.priceByPeriod(MONTHLY_PERIOD, true);
        },
        saveYearly: function () {
            return (Number(this.monthlyPrice.replace(',', '')) * 12 -
                Number(this.yearlyPrice.replace(',', ''))).toString();
        },
        otherPriceOptionsText: function () {
            if (this.period === YEARLY_PERIOD) {
                return "".concat(this.monthlyPrice, " / month");
            }
            return "".concat(this.yearlyPrice, " / year");
        },
        isBuyNowButtonVisible: function () {
            return this.parentData.totalCattle > 0 || this.parentData.totalSheep > 0;
        },
    },
    watch: {
        parentData: {
            deep: true,
            handler: function (newValue) {
                this.calculatePrice();
            },
        },
    },
    methods: {
        updateStickyPlan: function () {
            var _a;
            var headerHeight = this.$mainHeader.height() || 0;
            var wpAdminBarHeight = this.$wpAdminBar.height() || 0;
            var regionBannerHeight = this.$regionBanner.height() || 0;
            var offsetTop = headerHeight + wpAdminBarHeight + (regionBannerHeight > 0 ? regionBannerHeight + 15 : 0);
            for (var _i = 0, _b = ((_a = this.$planHeader) === null || _a === void 0 ? void 0 : _a.toArray()) || []; _i < _b.length; _i++) {
                var header = _b[_i];
                var $header = $(header);
                var $parent = $header.parent();
                var $content = this.$planContent;
                var stickyHeight = $header.outerHeight() || 0;
                var parentOffset = $parent.offset() || {
                    top: 0,
                    left: 0,
                };
                var parentWidth = $parent.width() || 0;
                var isFlewBy = window.scrollY > parentOffset.top - offsetTop;
                $header.css('position', isFlewBy ? 'fixed' : 'relative');
                $header.css('top', isFlewBy ? "".concat(offsetTop, "px") : 'auto');
                $header.css('left', isFlewBy ? "".concat(parentOffset.left, "px") : 'auto');
                $header.css('width', isFlewBy ? "".concat(parentWidth, "px") : '100%');
                $content === null || $content === void 0 ? void 0 : $content.css('marginTop', isFlewBy ? "".concat(stickyHeight, "px") : '0');
            }
        },
        // TODO: progressive refactor soon
        updateAddonsDisplay: function () {
            var _a = this.parentData || {}, _b = _a.totalCattle, cattle = _b === void 0 ? 0 : _b, _c = _a.totalSheep, sheep = _c === void 0 ? 0 : _c;
            var dseNumber = cattle * 8 + sheep * 1.5;
            var parentCards = document.querySelectorAll('.parent-card');
            var buyNowCta = document.querySelectorAll('.plan-cta-buy-now');
            parentCards.forEach(function (parentCard) {
                var hasVisibleItems = false;
                // Check each add-on and integration section within the parent card
                var addonSections = parentCard.querySelectorAll('.add-on-section, .integration-section');
                addonSections.forEach(function (section) {
                    var showThreshold = parseInt(section.getAttribute('data-show-addons') || '0');
                    var sectionHasVisibleItems = false;
                    var items = section.querySelectorAll('.add-on-item, .integration-item');
                    items.forEach(function (item) {
                        var itemThreshold = parseInt(item.getAttribute('data-show') || '0');
                        // If itemThreshold is 0, it inherits the parent's showThreshold
                        if (itemThreshold === 0) {
                            itemThreshold = showThreshold;
                        }
                        // Show or hide the item based on the DSE number
                        if (dseNumber >= itemThreshold) {
                            item.classList.remove('hidden');
                            sectionHasVisibleItems = true;
                        }
                        else {
                            item.classList.add('hidden');
                        }
                    });
                    // Show/hide the section based on visible items
                    if (sectionHasVisibleItems) {
                        section.classList.remove('hidden');
                        hasVisibleItems = true;
                    }
                    else {
                        section.classList.add('hidden');
                    }
                });
                // Hide the parent card if no visible items in any section
                if (hasVisibleItems) {
                    parentCard.classList.remove('hidden');
                }
                else {
                    parentCard.classList.add('hidden');
                }
            });
            // Change the parameter of buy now link
            buyNowCta.forEach(function (cta) {
                var ctaHref = cta.getAttribute('href');
                var ctaUrl = new URL(ctaHref !== null && ctaHref !== void 0 ? ctaHref : '');
                ctaUrl.searchParams.set('cattle', cattle);
                ctaUrl.searchParams.set('sheep', sheep);
                cta.setAttribute('href', ctaUrl.href);
            });
        },
        updateEmptyPrice: function (countryCode, planName, period, dseThreshold) {
            var priceStep = calculator.getPriceStep(countryCode, planName, period, dseThreshold);
            if (!priceStep) {
                var priceDetails = document.querySelectorAll('.price-detail');
                var priceSummary = document.querySelectorAll('.price-summary');
                var priceCtaTrial = document.querySelectorAll('.plan-cta-trial');
                var priceCtaTrialHidden = document.querySelectorAll('.plan-cta-trial-hidden');
                var priceStickyHeader = document.querySelectorAll('.sticky-header');
                var priceDiscountBadge = document.querySelectorAll('.discount-badge');
                var priceCtaBuynowButton = document.querySelectorAll('.plan-cta-button');
                var priceCtaBuynowHidden = document.querySelectorAll('.missing-price-region');
                // priceDetails
                priceDetails === null || priceDetails === void 0 ? void 0 : priceDetails.forEach(function (item) {
                    item.classList.add('hidden');
                });
                // priceSummary
                priceSummary === null || priceSummary === void 0 ? void 0 : priceSummary.forEach(function (item) {
                    item.classList.add('hidden');
                });
                // price cta trial
                priceCtaTrial === null || priceCtaTrial === void 0 ? void 0 : priceCtaTrial.forEach(function (item) {
                    item.classList.add('!hidden');
                });
                // price cta trial hidden
                priceCtaTrialHidden === null || priceCtaTrialHidden === void 0 ? void 0 : priceCtaTrialHidden.forEach(function (item) {
                    item.classList.remove('!hidden');
                });
                // resize sticky header
                priceStickyHeader === null || priceStickyHeader === void 0 ? void 0 : priceStickyHeader.forEach(function (item) {
                    item.classList.remove('h-[229px]');
                    item.classList.add('h-[150px]');
                });
                // price discountBadge
                priceDiscountBadge === null || priceDiscountBadge === void 0 ? void 0 : priceDiscountBadge.forEach(function (item) {
                    item.classList.add('!hidden');
                });
                // price cta buynow button
                priceCtaBuynowButton === null || priceCtaBuynowButton === void 0 ? void 0 : priceCtaBuynowButton.forEach(function (item) {
                    item.classList.add('!hidden');
                });
                // price cta trial hidden
                priceCtaBuynowHidden === null || priceCtaBuynowHidden === void 0 ? void 0 : priceCtaBuynowHidden.forEach(function (item) {
                    item.classList.remove('!hidden');
                });
            }
        },
        priceByPeriod: function (period, withDiscount) {
            if (withDiscount === void 0) { withDiscount = false; }
            var _a = this.parentData || {}, _b = _a.totalCattle, cattle = _b === void 0 ? 0 : _b, _c = _a.totalSheep, sheep = _c === void 0 ? 0 : _c;
            calculator.setUnits({ cattle: cattle, sheep: sheep });
            this.updateEmptyPrice(this.countryCode || '', this.planName || '', period, this.dseThreshold || 40000);
            return calculator.getPrice(this.countryCode || '', this.planName || '', period, this.dseThreshold || 40000, withDiscount ? this.discountPercentage || 0 : 0);
        },
        calculatePrice: function () {
            this.totalPrice = this.priceByPeriod(this.period, true);
            this.originalPrice = this.priceByPeriod(this.period, false);
            this.updateAddonsDisplay();
        },
        handleOpenBuyNowPopup: function () {
            this.showModalBuyNow = true;
        },
        handleCloseBuyNowPopup: function () {
            this.showModalBuyNow = false;
        },
    },
    created: function () {
        window.addEventListener('scroll', this.updateStickyPlan);
        window.addEventListener('resize', this.updateStickyPlan);
    },
    beforeDestroy: function () {
        window.removeEventListener('scroll', this.updateStickyPlan);
        window.removeEventListener('resize', this.updateStickyPlan);
    },
    mounted: function () {
        this.calculatePrice();
    },
}));
