"use strict";
function showLiveChat() {
    var liveChatButton = document.querySelectorAll('.start-live-chat');
    liveChatButton.forEach(function (live) {
        live.addEventListener('click', function (event) {
            event.preventDefault();
            var intercomLuncher = document.querySelectorAll('.intercom-launcher');
            if (intercomLuncher.length) {
                intercomLuncher.forEach(function (launcher) {
                    launcher.click();
                });
            }
            else {
                triggerIntercomIframe();
            }
        });
    });
}
function triggerIntercomIframe() {
    var _a;
    var intercomApp = document.querySelectorAll('.intercom-launcher-frame');
    if (intercomApp) {
        var intercomDoc = intercomApp[0].contentDocument || ((_a = intercomApp[0].contentWindow) === null || _a === void 0 ? void 0 : _a.document);
        var intercomIframeLauncher = intercomDoc === null || intercomDoc === void 0 ? void 0 : intercomDoc.querySelectorAll('.intercom-launcher');
        if (intercomIframeLauncher) {
            intercomIframeLauncher[0].click();
        }
    }
}
function setTimeoutTryForFree() {
    var interval = setInterval(function () {
        var element = document.querySelector('.intercom-lightweight-app');
        if (element) {
            clearInterval(interval);
            element.style.visibility = 'hidden';
            setTimeout(function () {
                element.style.visibility = 'visible';
            }, 30000);
        }
    }, 10);
}
document.addEventListener('DOMContentLoaded', function () {
    var currentPath = window.location.pathname;
    if (currentPath === '/try-for-free/') {
        setTimeoutTryForFree();
    }
    showLiveChat();
});
