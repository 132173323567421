import Vue from 'vue';
import ScrollTo from '@/parts/scroll-to';
import $ from '@/lib/jquery';
import ComponentUtilsMixin from '@/helpers/mixins/component-utils-mixins';
import $globalElements from '@/helpers/global-elements';
Vue.component('template-pricing', {
    mixins: [ComponentUtilsMixin],
    data: function () { return ({
        period: 'yearly',
        totalCattle: 0,
        totalSheep: 0,
    }); },
    computed: {
        $calculator: function () {
            var _a;
            return $((_a = this.findChildren('pricing-plan-calculator')) === null || _a === void 0 ? void 0 : _a.$el);
        },
        $featuredCardFixed: function () {
            return this.$calculator.find('#feature-card-fixed');
        },
        $sectionCompare: function () {
            return $(this.$el).find('#section-compare');
        },
        $mainHeader: function () {
            return $globalElements.$mainHeader();
        },
        $wpAdminBar: function () {
            return $globalElements.$wpAdminBar();
        },
        $planHeader: function () {
            return this.$refs.planHeaderMobile ? $(this.$refs.planHeaderMobile) : null;
        },
    },
    methods: {
        gotoCompare: function () {
            var _a;
            var height = this.$featuredCardFixed.height() || 0;
            var positionTop = this.$featuredCardFixed.position().top || 0;
            var offsetSticky = height + positionTop;
            var top = ((_a = this.$sectionCompare.offset()) === null || _a === void 0 ? void 0 : _a.top) || 0;
            ScrollTo.scrollTop(top - offsetSticky);
        },
        updateStickyPlan: function () {
            var headerHeight = this.$mainHeader.height() || 0;
            var wpAdminBarHeight = this.$wpAdminBar.height() || 0;
            var offsetTop = headerHeight + wpAdminBarHeight;
            // console.log('planHeaderMobile', this.$planHeader?.toArray())
            // for (const header of this.$planHeader?.toArray() || []) {
            //   const $header = $(header)
            //   const $parent = $header.parent()
            //   const stickyHeight = $header.outerHeight() || 0
            //   const parentOffset = $parent.offset() || {
            //     top: 0,
            //     left: 0,
            //   }
            //   // const parentWidth = $parent.width() || 0
            //   const isFlewBy = window.scrollY > parentOffset.top - offsetTop
            //   $header.css('position', isFlewBy ? 'fixed' : 'relative')
            //   $header.css('top', isFlewBy ? `${offsetTop}px` : 'auto')
            //   $header.css('left', isFlewBy ? `${parentOffset.left}px` : 'auto')
            //   $header.css('width', isFlewBy ? `${parentWidth}px` : '100%')
            // }
        },
    },
    created: function () {
        window.addEventListener('scroll', this.updateStickyPlan);
        window.addEventListener('resize', this.updateStickyPlan);
    },
    beforeDestroy: function () {
        window.removeEventListener('scroll', this.updateStickyPlan);
        window.removeEventListener('resize', this.updateStickyPlan);
    },
});
