export var templateLegalPage = function () {
    var stickyElement = document.getElementById('sticky-legal-menu');
    var mainContent = document.getElementById('template-legal-page');
    if (!stickyElement || !mainContent) {
        console.error("Required elements are not found.");
        return;
    }
    var mainContentBottom = mainContent.getBoundingClientRect().bottom + window.scrollY;
    // Initially, show sticky element after loading
    stickyElement.classList.remove('translate-y-full');
};
