var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue, { defineComponent } from 'vue';
import { createTemplate } from '@/lib/vue';
var defaultProps = {
    attributes: {
        ':posts': 'posts',
        ':on-grid-click': 'onGridClick',
        ':on-click-read-more': 'onClickReadMore',
    }
};
export var createPostGridTemplate = function (props) {
    if (props === void 0) { props = null; }
    return createTemplate(function (_a) {
        var attributes = _a.attributes;
        return "\n    <div class=\"flex-col w-full flex\">\n      <section v-if=\"posts.length === 0\" class=\"page-404 text-center h-[50vh] flex flex-col justify-center items-center\">\n        <div class=\"page-404-content\">\n          <div class=\"page-404-content-footer mb-5 lato-400\">\n            <h2 class=\"lato-400 text-[36px] leading-[56px]\">Data not found</h2>\n            <p class=\"text-[18px]\">Please adjust your search and try again.</p>\n          </div>\n        </div>\n      </section>\n      <div v-else>\n        <slot ".concat(attributes, "></slot>\n        <pagination\n          v-if=\"totalPages > 1\"\n          :total=\"totalData\"\n          :current-page=\"currentPage\"\n          :per-page=\"perPage\"\n          v-on:page-change=\"handlePageChange\"\n          v-on:page-prev=\"handlePagePrev\"\n          v-on:page-next=\"handlePageNext\"\n        ></pagination>\n      </div>\n    </div>\n  ");
    }, props, defaultProps);
};
export default Vue.component('post-grid', defineComponent({
    template: createPostGridTemplate(),
    props: {
        jsonData: {
            type: String,
            default: function () { return ''; },
        },
        initialTerms: {
            type: Array,
            default: function () { return []; },
        },
    },
    data: function () {
        return {
            perPage: 30,
            currentPage: 1,
            terms: this.initialTerms || [],
        };
    },
    computed: {
        allPosts: function () {
            try {
                return JSON.parse(this.jsonData);
            }
            catch (error) {
                console.error('Error parsing JSON:', error);
                return [];
            }
        },
        filteredPosts: function () {
            var _this = this;
            var newPosts = __spreadArray([], this.allPosts, true);
            // Filter posts based on terms
            if (this.terms.length > 0) {
                var groupedTerms_1 = this.terms.reduce(function (acc, item) {
                    if (acc[item.taxonomy]) {
                        acc[item.taxonomy].push(item);
                    }
                    else {
                        acc[item.taxonomy] = [item];
                    }
                    return acc;
                }, {});
                var termKeys = Object.keys(groupedTerms_1);
                if (termKeys.length <= 1) {
                    newPosts = newPosts.filter(function (post) {
                        return _this.terms.some(function (term) {
                            return post.terms.find(function (t) {
                                var _a, _b, _c, _d;
                                return ((_a = t.taxonomy) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = term.taxonomy) === null || _b === void 0 ? void 0 : _b.toLowerCase()) &&
                                    ((_c = t.slug) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === ((_d = term.slug) === null || _d === void 0 ? void 0 : _d.toLowerCase());
                            });
                        });
                    });
                }
                else {
                    var _loop_1 = function (termKey) {
                        newPosts = newPosts.filter(function (post) {
                            return groupedTerms_1[termKey].every(function (term) {
                                return post.terms.find(function (t) {
                                    var _a, _b, _c, _d;
                                    return ((_a = t.taxonomy) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = term.taxonomy) === null || _b === void 0 ? void 0 : _b.toLowerCase()) &&
                                        ((_c = t.slug) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === ((_d = term.slug) === null || _d === void 0 ? void 0 : _d.toLowerCase());
                                });
                            });
                        });
                    };
                    for (var _i = 0, termKeys_1 = termKeys; _i < termKeys_1.length; _i++) {
                        var termKey = termKeys_1[_i];
                        _loop_1(termKey);
                    }
                }
            }
            return newPosts;
        },
        posts: function () {
            var startIndex = (this.currentPage - 1) * this.perPage;
            var endIndex = startIndex + this.perPage;
            return this.filteredPosts.slice(startIndex, endIndex);
        },
        totalPages: function () {
            return Math.ceil(this.totalData / this.perPage);
        },
        totalData: function () {
            return this.filteredPosts.length;
        },
    },
    watch: {
        initialTerms: function (newVal) {
            this.terms = newVal;
        },
        currentPage: function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
    methods: {
        onGridClick: function (data) {
            this.$emit('grid-click', data);
        },
        onClickReadMore: function (event, permalink) {
            event.stopPropagation();
            window.location.href = permalink;
        },
        handlePageChange: function (page) {
            this.currentPage = page;
        },
        handlePagePrev: function () {
            this.currentPage--;
        },
        handlePageNext: function () {
            this.currentPage++;
        },
        applyTermsFromUrl: function () {
            var _a;
            this.terms = [];
            var params = new URLSearchParams(window.location.search);
            if (params.get('terms')) {
                var terms = ((_a = params.get('terms')) === null || _a === void 0 ? void 0 : _a.split('|')) || [];
                for (var _i = 0, terms_1 = terms; _i < terms_1.length; _i++) {
                    var term = terms_1[_i];
                    var taxonomy = term.split(':')[0];
                    var slug = term.split(':')[1];
                    this.terms.push({ taxonomy: taxonomy, slug: slug });
                }
            }
        },
        // Find all terms
        findPostTerms: function (post, taxonomy) {
            return post.terms.filter(function (t) { return t.taxonomy === taxonomy; });
        },
        // Find all term names
        findPostTermNames: function (post, taxonomy) {
            return this.findPostTerms(post, taxonomy).map(function (t) { return t.name; });
        },
        // Find a single term
        findPostTerm: function (post, taxonomy) {
            return post.terms.find(function (t) { return t.taxonomy === taxonomy; });
        },
        // Find a single term name
        findPostTermName: function (post, taxonomy) {
            var term = this.findPostTerm(post, taxonomy);
            return (term === null || term === void 0 ? void 0 : term.name) || null;
        },
    },
    mounted: function () {
        this.applyTermsFromUrl();
    },
}));
