import Swiper from 'swiper';
import 'swiper/css';
export var blockScrollingLogoCarousel = function () {
    document.querySelectorAll('.companies-slider').forEach(function (slider) {
        new Swiper(slider, {
            slidesPerView: "auto",
            spaceBetween: 31,
            loop: true,
            autoplay: false,
            mousewheel: true,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            on: {
                init: function () {
                    var swiperWrapper = slider.querySelector('.swiper-wrapper');
                    var slides = slider.querySelectorAll('.swiper-slide');
                    if (slides.length <= 4) {
                        swiperWrapper.style.justifyContent = 'center';
                    }
                    else {
                        swiperWrapper.style.justifyContent = 'flex-start'; // Default alignment
                    }
                }
            }
        });
    });
};
