export function anchorLink() {
    document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
        anchor.addEventListener("click", function (e) {
            var _a;
            e.preventDefault();
            var targetId = (_a = e.currentTarget.getAttribute("href")) === null || _a === void 0 ? void 0 : _a.substring(1);
            var targetElement = targetId ? document.getElementById(targetId) : null;
            var offset = 150;
            if (targetElement) {
                var targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;
                window.scrollTo({
                    top: targetPosition,
                    behavior: "smooth"
                });
            }
        });
    });
}
