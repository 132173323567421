"use strict";
// ============================== ATTENTION ==============================
// TODO: refactor this script to be more generic and reusable
// ==============================
var bindCtaFooter = function () {
    var ctaFooter = document.getElementById("cta-footer");
    if (ctaFooter) {
        // Get the height of the container element
        var height = ctaFooter.offsetHeight;
        // Set the margin-top to half of the height
        document.documentElement.style.setProperty('--cta-footer-offset', "".concat(height / 2, "px"));
        return;
    }
};
document.addEventListener("DOMContentLoaded", function () {
    bindCtaFooter();
});
document.addEventListener("resize", function () {
    bindCtaFooter();
});
