import videojs from 'video.js';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { calculateProgress } from '@/helpers/swiper';
export var blockHorizontalScrollingSection = function () {
    var progressIndicator = document.querySelector('.progress-indicator-horizontal');
    new Swiper('.block-horizontal-swiper', {
        modules: [Navigation, Pagination, Autoplay],
        autoplay: false,
        mousewheel: true,
        navigation: {
            nextEl: ".custom-button-next",
            prevEl: ".custom-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
            // progressbarFillClass: "swiper-pagination-progressbar-fill !bg-[#39A7C4]",
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesOffsetBefore: 0,
            },
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesOffsetBefore: 0,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesOffsetBefore: 0,
            },
            1024: {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesOffsetBefore: 0,
            },
            1280: {
                slidesPerView: 1.5,
                spaceBetween: 20,
                slidesOffsetBefore: 20,
            },
            1920: {
                slidesPerView: 2,
                spaceBetween: 20,
                slidesOffsetBefore: 20,
            },
            2560: {
                slidesPerView: 2.5,
                spaceBetween: 20,
                slidesOffsetBefore: 30,
            },
            3840: {
                slidesPerView: 3,
                spaceBetween: 20,
                slidesOffsetBefore: 30,
            },
        },
        on: {
            slideChange: function (swiperInstance) {
                if (swiperInstance.isBeginning) {
                    progressIndicator.style.width = '0%';
                }
                else if (swiperInstance.isEnd) {
                    progressIndicator.style.width = '100%';
                }
                else if (swiperInstance.slides.length >= swiperInstance.slidesPerViewDynamic() && progressIndicator) {
                    var progress = calculateProgress(swiperInstance);
                    progressIndicator.style.width = "".concat(progress, "%");
                }
            },
        }
    });
};
export var toggleVideoPopup = function () {
    var videoThumbnails = document.querySelectorAll('.watch-story-button');
    // Only proceed if there are video thumbnails with media URLs
    if (videoThumbnails.length === 0)
        return;
    videoThumbnails.forEach(function (button) {
        // Select the sibling video popup element directly
        var videoPopup = button.nextElementSibling;
        // Check if the video popup element exists
        if (!videoPopup || !videoPopup.classList.contains('video-popup'))
            return;
        var closePopupButton = videoPopup.querySelector('.close-popup');
        var playPauseButton = videoPopup.querySelector('.play-pause-button');
        var videoElement = videoPopup.querySelector("video");
        var videoSource = videoPopup.querySelector("source");
        // Initialize video.js player for this instance
        var player = videojs(videoElement, {
            controls: false,
            autoplay: false,
            muted: true, // Initially muted for compatibility
            loop: true, // Enable video loop
        });
        // Function to open the video popup
        function openVideoPopup() {
            videoPopup.classList.remove('hidden');
            videoPopup.classList.add('flex');
            player.muted(false); // Unmute the video on autoplay
            player.play(); // Autoplay when popup opens
        }
        // Function to close the video popup and reset the video
        function closeVideoPopup() {
            videoPopup.classList.add('hidden');
            videoPopup.classList.remove('flex');
            player.pause(); // Pause the video when the popup closes
            player.currentTime(0); // Reset video to the beginning
        }
        // Open popup when clicking the "Watch Story" button
        button.addEventListener('click', function () {
            openVideoPopup();
        });
        // Close popup when clicking the close button
        closePopupButton.addEventListener('click', closeVideoPopup);
        // Close popup when clicking outside the video content
        videoPopup.addEventListener('click', function (event) {
            if (event.target === videoPopup) {
                closeVideoPopup();
            }
        });
        // Custom Play/Pause button handler
        playPauseButton.addEventListener('click', function () {
            if (player.paused()) {
                player.play();
            }
            else {
                player.pause();
            }
        });
    });
};
