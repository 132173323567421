// ================================ ATTENTION ================================
// TODO: refactor this to use vue approach
// ================================ ATTENTION ================================
export var blockSummaryHero = function () {
    // Get the necessary elements by ID
    var summaryHeroSection = document.getElementById("block-summary-hero");
    var blockSection = document.getElementById("block-section");
    var innerBlocksContainer = document.getElementById("inner-blocks-container");
    var blockTabbedReveal = document.getElementById("block-tabbed-reveal");
    var blockSectionContainer = document.getElementById("block-section-container");
    var variant = summaryHeroSection === null || summaryHeroSection === void 0 ? void 0 : summaryHeroSection.getAttribute("data-variant");
    var withAnimation = variant === "WITH_ANIMATION";
    // Check if blockSection contains summaryHeroSection, then add classes if inner block has children
    if (blockSection && summaryHeroSection && blockSection.contains(summaryHeroSection)) {
        var innerBlock = summaryHeroSection.querySelector(".inner-block");
        if (innerBlock && innerBlock.children.length > 0) {
            innerBlock.classList.add("pt-[40px]", "-mb-[28%]");
            blockSection.classList.add("mb-[23.8%]");
        }
    }
    // Check if blockTabbedReveal is within innerBlocksContainer and adjust width class
    if (innerBlocksContainer && blockTabbedReveal && innerBlocksContainer.contains(blockTabbedReveal)) {
        innerBlocksContainer.classList.remove("md:w-[70%]");
    }
    // If blockTabbedReveal is found in innerBlocksContainer, set margin-bottom for blockSectionContainer
    if (blockSectionContainer && innerBlocksContainer && blockTabbedReveal) {
        var innerBlocksHeight = innerBlocksContainer.offsetHeight;
        blockSectionContainer.style.marginBottom = "".concat(innerBlocksHeight * 0.6, "px");
    }
    // Append animation to the hero cover block image
    if (withAnimation) {
        var hasOverlay = summaryHeroSection === null || summaryHeroSection === void 0 ? void 0 : summaryHeroSection.querySelector("#block-image .overlay-information");
        var sectionMultimedia = summaryHeroSection === null || summaryHeroSection === void 0 ? void 0 : summaryHeroSection.querySelector("#block-image section.multimedia-thumbnail .image-container");
        var overlayAnimationFrame = document.getElementById("overlay-animation-frame");
        var overlayAnimationFrameValue = overlayAnimationFrame === null || overlayAnimationFrame === void 0 ? void 0 : overlayAnimationFrame.value;
        if (sectionMultimedia && overlayAnimationFrameValue) {
            var overlayAnimationFrameData = void 0;
            try {
                overlayAnimationFrameData = JSON.parse(overlayAnimationFrameValue);
            }
            catch (error) {
                console.error("Error parsing overlay animation frame data:", error);
            }
            var animation_1 = document.createElement("div");
            if (!hasOverlay) {
                animation_1.classList.add("absolute", "bottom-0", "left-0", "right-0");
            }
            else {
                animation_1.classList.add("absolute", "bottom-auto", "md:bottom-[180px]", "left-0", "right-0");
            }
            var nonMobileAnimationImages = (overlayAnimationFrameData === null || overlayAnimationFrameData === void 0 ? void 0 : overlayAnimationFrameData.map(function (item) { return item.non_mobile_frame_image; })) || [];
            var mobileAnimationImages = (overlayAnimationFrameData === null || overlayAnimationFrameData === void 0 ? void 0 : overlayAnimationFrameData.map(function (item) { return item.mobile_frame_image; })) || [];
            nonMobileAnimationImages.forEach(function (image, index) {
                var img = document.createElement("img");
                img.src = image;
                img.alt = "Sustainability Hero Animation";
                img.classList.add("absolute", "bottom-0", "left-0", "right-0", "animate-fadeSequence", "opacity-0", "!w-full", '!h-auto', "mx-auto", "!object-contain", "hidden", "md:block");
                img.style.animationDelay = "".concat(index * 2, "s");
                animation_1.appendChild(img);
            });
            mobileAnimationImages.forEach(function (image, index) {
                var img = document.createElement("img");
                img.src = image;
                img.alt = "Sustainability Hero Animation";
                img.classList.add("absolute", "bottom-0", "left-0", "right-0", "animate-fadeSequence", "opacity-0", "!w-[340px]", "!h-auto", "mx-auto", "!object-contain", "block", "md:hidden");
                img.style.animationDelay = "".concat(index * 2, "s");
                animation_1.appendChild(img);
            });
            sectionMultimedia.appendChild(animation_1);
        }
    }
};
