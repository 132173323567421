import videojs from 'video.js';
import Vue, { defineComponent } from 'vue';
import 'videojs-youtube/dist/Youtube.js';
import './videojs-vimeo';
import modal from '@/parts/modal';
var $ = jQuery;
window.Multimedia = {
    init: function () {
        var multimediasEls = $('.multimedia-video');
        multimediasEls.each(function (i, multimediaEl) {
            var videoEl = $('.video-js', multimediaEl);
            var videoContainer = $('.video-container', multimediaEl);
            // just make sure not loaded this multiple times
            if (!videoEl.data('playerInit') && videoEl[0]) {
                // setup the player
                var player_1 = videojs(videoEl[0], { controls: false });
                videoEl.data('player', player_1);
                player_1.ready(function () {
                    videoContainer.find('.video-js').removeClass('hidden');
                });
                // play/pause handler
                var playPauseEl_1 = $('.play-pause-button', multimediaEl);
                var playIconSvg_1 = "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n    <path d=\"M12 3C7.03711 3 3 7.03711 3 12C3 16.9629 7.03711 21 12 21C16.9629 21 21 16.9629 21 12C21 7.03711 16.9629 3 12 3ZM12 4.5C16.1514 4.5 19.5 7.84863 19.5 12C19.5 16.1514 16.1514 19.5 12 19.5C7.84863 19.5 4.5 16.1514 4.5 12C4.5 7.84863 7.84863 4.5 12 4.5ZM10 8.25L15.75 12L10 15.75V8.25Z\" fill=\"white\"/>\n</svg>";
                var pauseIconSvg_1 = "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M12 3C7.03711 3 3 7.03711 3 12C3 16.9629 7.03711 21 12 21C16.9629 21 21 16.9629 21 12C21 7.03711 16.9629 3 12 3ZM12 4.5C16.1514 4.5 19.5 7.84863 19.5 12C19.5 16.1514 16.1514 19.5 12 19.5C7.84863 19.5 4.5 16.1514 4.5 12C4.5 7.84863 7.84863 4.5 12 4.5ZM9 8.25V15.75H10.5V8.25H9ZM13.5 8.25V15.75H15V8.25H13.5Z\" fill=\"white\"/>\n</svg>\n";
                playPauseEl_1.on('click', function () {
                    if (player_1.paused()) {
                        player_1.play();
                        playPauseEl_1.html(pauseIconSvg_1);
                    }
                    else {
                        player_1.pause();
                        playPauseEl_1.html(playIconSvg_1);
                    }
                });
                playPauseEl_1.html(pauseIconSvg_1);
                videoEl.data('playerInit', true);
            }
        });
    },
};
jQuery(function () {
    window.Multimedia.init();
});
Vue.component('multimedia-popup', {
    data: function () {
        return {
            isOpen: false,
        };
    },
    methods: {
        handleOpen: function () {
            this.isOpen = true;
        },
        handleClose: function () {
            this.isOpen = false;
        },
    }
});
Vue.component('modal-multimedia-popup', 
// @ts-ignore
defineComponent({
    extends: modal,
    methods: {
        init: function () {
            var multimediaEl = $('.multimedia-popup-video');
            var videoEl = $('.video-js', multimediaEl);
            if (!videoEl.data('playerInit') && videoEl[0]) {
                var player = videojs(videoEl[0], { controls: false });
                videoEl.data('player', player);
                player.play();
            }
        },
    },
    watch: {
        open: {
            immediate: true,
            handler: function (value) {
                var _this = this;
                if (value) {
                    this.delayedOpen();
                    setTimeout(function () {
                        _this.init();
                    }, 300);
                }
                else {
                    this.delayedClose();
                }
            },
        },
    },
}));
