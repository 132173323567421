// ==============================ATTENTION========================================
// TODO: refactor this later
// ==============================ATTENTION========================================
export function createFooterAccordion() {
    var footerContainer = document.querySelector('#footer-main');
    if (!footerContainer)
        return;
    var accordionItems = footerContainer.querySelectorAll('[data-accordion-item]');
    accordionItems.forEach(function (item) {
        var isActive = false;
        var trigger = item.querySelector('[data-accordion-trigger]');
        var content = item.querySelector('[data-accordion-content]');
        trigger === null || trigger === void 0 ? void 0 : trigger.addEventListener('click', function () {
            if (!isActive) {
                content === null || content === void 0 ? void 0 : content.classList.add('h-full');
                content === null || content === void 0 ? void 0 : content.classList.add('pb-[20px]');
                content === null || content === void 0 ? void 0 : content.classList.remove('md:h-0');
                content === null || content === void 0 ? void 0 : content.classList.remove('lg:h-full');
                trigger === null || trigger === void 0 ? void 0 : trigger.classList.add('rotate-180');
            }
            else {
                content === null || content === void 0 ? void 0 : content.classList.remove('h-full');
                content === null || content === void 0 ? void 0 : content.classList.add('md:h-0');
                content === null || content === void 0 ? void 0 : content.classList.add('lg:h-full');
                content === null || content === void 0 ? void 0 : content.classList.remove('pb-[20px]');
                trigger === null || trigger === void 0 ? void 0 : trigger.classList.remove('rotate-180');
            }
            isActive = !isActive;
        });
    });
}
