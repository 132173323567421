import ComponentUtilsMixin from '@/helpers/mixins/component-utils-mixins';
import Vue from 'vue';
Vue.component('pricing-plan-calculator', {
    mixins: [ComponentUtilsMixin],
    data: function () {
        return {
            mode: 'manual',
        };
    },
    computed: {
        parentData: function () {
            return this.getParentData('template-pricing') || {};
        },
    },
    methods: {
        onTogglePeriod: function (value) {
            this.parentData.period = value;
        },
        onToggleTotalCattle: function (value) {
            this.parentData.totalCattle = Number(value);
        },
        onToggleTotalSheep: function (value) {
            this.parentData.totalSheep = Number(value);
        },
        onToggleMode: function (value) {
            this.mode = value;
        },
    }
});
