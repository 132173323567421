import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { calculateProgress } from '@/helpers/swiper';
import Vue from 'vue';
export default Vue.component('block-image-profile-carousel', {
    data: function () {
        return {
            selectedPost: null,
            isDrawerOpen: false,
        };
    },
    mounted: function () {
        this.initSwiper();
    },
    methods: {
        onGridClick: function (data) {
            this.selectedPost = data;
            this.isDrawerOpen = true;
        },
        onDrawerClose: function () {
            this.isDrawerOpen = false;
            this.selectedPost = null;
        },
        initSwiper: function () {
            var progressIndicator = document.querySelector('.progress-indicator-image-profile');
            new Swiper('.block-image-profile-carousel-slider', {
                // configure Swiper to use modules
                modules: [Navigation, Pagination, Autoplay],
                slidesPerView: 'auto',
                spaceBetween: 30,
                autoplay: false,
                mousewheel: true,
                loop: true,
                navigation: {
                    nextEl: ".custom-button-next",
                    prevEl: ".custom-button-prev",
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1.2,
                        spaceBetween: 10,
                        slidesOffsetBefore: 20,
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        slidesOffsetBefore: 20,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                        slidesOffsetBefore: 20,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                },
                on: {
                    slideChange: function (swiperInstance) {
                        if (swiperInstance.slides.length > 1 && progressIndicator) {
                            var progress = calculateProgress(swiperInstance);
                            progressIndicator.style.width = "".concat(progress, "%");
                        }
                    },
                }
            });
        }
    }
});
