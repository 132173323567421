import $ from '@/lib/jquery';
import Vue from 'vue';
Vue.component('dropdown', {
    props: {
        placement: {
            type: String,
            default: 'bottom'
        },
        offset: {
            type: Number,
            default: 0
        },
        containerClass: {
            type: String,
            default: 'relative inline-block text-left'
        },
        containerActiveClass: {
            type: String,
            default: 'open'
        },
        contentClass: {
            type: String,
            default: 'absolute z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        },
        triggerContainerClass: {
            type: String,
            default: 'relative inline-block text-left'
        },
        triggerClass: {
            type: String,
            default: 'inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        },
        triggerActiveClass: {
            type: String,
            default: 'active'
        },
        dropdownClass: {
            type: String,
            default: 'absolute z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        }
    },
    data: function () {
        return {
            isOpen: false
        };
    },
    watch: {
        isOpen: function (newVal) {
            var _this = this;
            this.$nextTick(function () {
                _this.toggleClasses(newVal);
                if (newVal) {
                    _this.position();
                }
            });
        }
    },
    computed: {
        $container: function () {
            return this.$refs.containerRef;
        },
        $trigger: function () {
            return this.$refs.triggerRef;
        },
        $content: function () {
            return this.$refs.contentRef;
        }
    },
    methods: {
        toggle: function () {
            this.isOpen = !this.isOpen;
        },
        position: function () {
            var triggerEl = this.$trigger;
            var contentEl = this.$content;
            if (!triggerEl || !contentEl)
                return;
            switch (this.placement) {
                case 'bottom':
                    contentEl.style.top = "calc(100% + ".concat(this.offset, "px)");
                    contentEl.style.left = "0";
                    break;
                case 'top':
                    // TODO: Implement top placement
                    break;
                case 'left':
                    // TODO: Implement left placement
                    break;
                case 'right':
                    // TODO: Implement right placement
                    break;
            }
        },
        toggleClasses: function (isOpen) {
            var containerEl = $(this.$container);
            var triggerEl = $(this.$trigger);
            var triggerIconEl = $(triggerEl.find('[data-trigger-icon]'));
            triggerIconEl.toggleClass('rotate-180', isOpen);
            triggerEl.toggleClass(this.triggerActiveClass, isOpen);
            containerEl.toggleClass(this.containerActiveClass, isOpen);
        },
        handleClickOutside: function (event) {
            if (!this.isOpen)
                return;
            var target = event.target;
            var triggerEl = this.$refs.triggerRef;
            var contentEl = this.$refs.contentRef;
            if (triggerEl &&
                contentEl &&
                !triggerEl.contains(target) &&
                !contentEl.contains(target)) {
                this.isOpen = false;
            }
        }
    },
    mounted: function () {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy: function () {
        document.removeEventListener('click', this.handleClickOutside);
    },
    template: "\n    <div ref=\"containerRef\" class=\"relative inline-block text-left\" :class=\"containerClass\">\n      <div \n        ref=\"triggerRef\"\n        @click=\"toggle\"\n        :class=\"triggerContainerClass\"\n      >\n        <slot name=\"trigger\" :is-open=\"isOpen\">\n          <button\n            type=\"button\"\n            :class=\"triggerClass\"\n          >\n            Options\n            <span data-trigger-icon class=\"transition-transform duration-300\">\n              <svg \n                class=\"w-5 h-5 ml-2 -mr-1\" \n                xmlns=\"http://www.w3.org/2000/svg\" \n                viewBox=\"0 0 20 20\" \n                fill=\"currentColor\"\n              >\n                <path \n                  fill-rule=\"evenodd\" \n                  d=\"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z\" \n                  clip-rule=\"evenodd\" \n                />\n              </svg>\n            </span>\n          </button>\n        </slot>\n      </div>\n  \n      <transition name=\"ease-in-out\">\n        <div\n          :class=\"contentClass\"\n          v-show=\"isOpen\"\n          ref=\"contentRef\">\n          <slot>\n            <a href=\"#\" class=\"block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100\">\n              Default Option\n            </a>\n          </slot>\n        </div>\n      </transition>\n    </div>\n  "
});
