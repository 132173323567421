import currency from 'currency.js';
var CalculatePricing = /** @class */ (function () {
    function CalculatePricing(priceJSON) {
        this.units = { cattle: 0, sheep: 0 };
        this.priceJSON = priceJSON;
    }
    CalculatePricing.prototype.setUnits = function (unitObject) {
        this.units = unitObject;
    };
    CalculatePricing.prototype.getDSENumber = function () {
        return this.units.cattle * 8 + this.units.sheep * 1.5;
    };
    CalculatePricing.prototype.formatPrice = function (price) {
        return currency(price, { symbol: '', precision: 0 })
            .format()
            .replace(/\.0+$/, '');
    };
    CalculatePricing.prototype.getPrice = function (region, plan, planPeriod, dseThreshold, saleDiscountPercentage) {
        var _a, _b, _c;
        if (saleDiscountPercentage === void 0) { saleDiscountPercentage = 0; }
        var dseNumber = this.getDSENumber();
        // Check if the DSE number exceeds the threshold
        if (dseNumber >= dseThreshold) {
            return 'Talk to Us'; // Display "Talk to Us" instead of price
        }
        var priceStep = (_c = (_b = (_a = this.priceJSON[region]) === null || _a === void 0 ? void 0 : _a[plan]) === null || _b === void 0 ? void 0 : _b[planPeriod]) === null || _c === void 0 ? void 0 : _c.find(function (step) { return dseNumber <= step.unitCap; });
        if (!priceStep) {
            console.error('No price found for the given DSE number in the specified region, plan, and period.');
            return '0';
        }
        var price = priceStep.price;
        if (saleDiscountPercentage > 0) {
            price -= (price * saleDiscountPercentage) / 100;
        }
        price = price > 0 ? price / 100 : 0;
        return this.formatPrice(price);
    };
    CalculatePricing.prototype.getPriceStep = function (region, plan, planPeriod, dseThreshold) {
        var _a, _b, _c;
        var dseNumber = this.getDSENumber();
        // Check if the DSE number exceeds the threshold
        if (dseNumber >= dseThreshold) {
            return 'Talk to Us'; // Display "Talk to Us" instead of price
        }
        var priceStep = (_c = (_b = (_a = this.priceJSON[region]) === null || _a === void 0 ? void 0 : _a[plan]) === null || _b === void 0 ? void 0 : _b[planPeriod]) === null || _c === void 0 ? void 0 : _c.find(function (step) { return dseNumber <= step.unitCap; });
        return priceStep ? priceStep : undefined;
    };
    return CalculatePricing;
}());
export { CalculatePricing };
